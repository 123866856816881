import { ___ } from 'classes/Translation';


export default () => ({
	label: 'BRICO MARCHE - BICO',
	technical: false,
	adds: {
		combo: false,
		fencings: false,
		wickets: false,
		gates: false,
		mailboxes: false,
		lamps: false,
		ledblocks: false,
		roofs: false,
	},
	blocks: {
		b: {
			visible: true,
			label: '-',
			path: '../../merkury-beno/blocks/b',
			options: {},
			colors: {
				marengo: { label: ___('Marengo') },
				piryt: { label: ___('Piryt') },
			},
			default: {
				blocksColor: 'marengo',
				peaksFamily: 'flat',
				peaksColor: 'marengo',
			},
			settings: {
				distance: { min: 0.403, default: 5 },
				autocorner: 'c2-bico20',
				offsets: false,
				sameAlignment: true,
			},
			pole: {
				default: {
					material: 'bico20',
					width: 1,
					depth: 1,
					height: 8,
				},
				materials: {
					bico20: {
						label: 'BICO20',
						modifiable: ['line'],
						seating: 0,
						block: {
							type: 'cuboid',
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'depth20.jpg', x: 0.2, y: 0.6 },
							},
							resizeable: { width: true, depth: false, height: true },
							minimum: { width: 1, depth: 1 },
							width: 0.403,
							depth: 0.20,
							height: 0.20,
						},
						peak1: {
							flat: 'dbc20',
						},
						peak2: {
							flat: 'dbc20',
						},
						peak3: {
							flat: 'dbc20',
						},
					},
					bico40: {
						label: 'BICO40',
						modifiable: ['line'],
						seating: 0,
						block: {
							type: 'cuboid',
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'depth40.jpg', x: 0.403, y: 0.6 },
							},
							resizeable: { width: true, depth: false, height: true },
							minimum: { width: 1, depth: 1 },
							width: 0.403,
							depth: 0.403,
							height: 0.20,
						},
						peak1: {
							flat: 'dbc40',
						},
						peak2: {
							flat: 'dbc40',
						},
						peak3: {
							flat: 'dbc40',
						},
					},
					'c2-bico20': {
						label: `${___('Narożnik')} BICO20`,
						modifiable: ['corner1', 'corner2'],
						seating: 0,
						block: {
							type: 'corner',
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								coupler1: { src: 'depth20.jpg', x: 0.2, y: 0.6 },
							},
							resizeable: { width: true, depth: true, height: true },
							minimum: { width: 1, depth: 1 },
							width: 0.403,
							depth: 0.403,
							height: 0.20,
							coupler: 0.20,
						},
						peak1: {
							flat: 'dbc20',
						},
						peak2: {
							flat: 'dbc20',
						},
						peak3: {
							flat: 'dbc20',
						},
					},
					'c1-bico40': {
						label: 'BICO40',
						modifiable: ['corner1'],
						modifiableReplacement: 'c2-bico40',
						seating: 0,
						block: {
							type: 'cuboid',
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'depth40.jpg', x: 0.403, y: 0.6 },
							},
							resizeable: { width: false, depth: false, height: true },
							minimum: { width: 1, depth: 1 },
							width: 0.403,
							depth: 0.403,
							height: 0.20,
						},
						peak1: {
							flat: 'dbc40',
						},
						peak2: {
							flat: 'dbc40',
						},
						peak3: {
							flat: 'dbc40',
						},
					},
					'c2-bico40': {
						label: `${___('Narożnik')} BICO40`,
						modifiable: ['corner2'],
						modifiableReplacement: 'c1-bico40',
						seating: 0,
						block: {
							type: 'corner',
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								coupler1: { src: 'depth40.jpg', x: 0.403, y: 0.6 },
							},
							resizeable: { width: true, depth: true, height: true },
							minimum: { width: 2, depth: 2 },
							width: 0.403,
							depth: 0.403,
							height: 0.20,
							coupler: 0.403,
						},
						peak1: {
							flat: 'dbc40',
						},
						peak2: {
							flat: 'dbc40',
						},
						peak3: {
							flat: 'dbc40',
						},
					},
				},
			},
			wall: {
				default: {
					material: 'bico20',
					depth: 1,
					height: 2,
				},
				materials: {
					bico20: {
						label: 'BICO20',
						support: ['bico20', 'bico40', 'c2-bico20', 'c1-bico40', 'c2-bico40'],
						block: {
							texture: {
								width1: { src: 'width.jpg', x: 1.209, y: 0.6 },
								depth1: { src: 'depth20.jpg', x: 0.2, y: 0.6 },
							},
							resizeable: { depth: false, height: true },
							width: 0.403,
							depth: 0.20,
							height: 0.20,
						},
						peak2: {
							flat: 'dbc20',
						},
						peak3: {
							flat: 'dbc20',
						},
					},
				},
			},
		},
	},
	peaks: {
		flat: {
			label: ___('Daszki płaskie'),
			path: '../../merkury-beno/peaks/flat',
			colors: {
				marengo: { label: ___('Marengo') },
				piryt: { label: ___('Piryt') },
			},
			materials: {
				dbc20: {
					label: `DBC20 ${___('daszek gładki')}`,
					type: 'flat2',
					texture: {
						width1: { src: 'width.jpg', x: 1.209, y: 0.05 },
						depth1: { src: 'depth20.jpg', x: 0.2, y: 0.05 },
						top: { src: 'top20.jpg', x: 1.209, y: 0.2 },
					},
					width: 0.403,
					depth: 0.20,
					height: 0.05,
					protrude: 0,
				},
				dbc40: {
					label: `DBC40 ${___('daszek gładki')}`,
					type: 'flat2',
					texture: {
						width1: { src: 'width.jpg', x: 1.209, y: 0.06 },
						depth1: { src: 'depth40.jpg', x: 0.403, y: 0.06 },
						top: { src: 'top40.jpg', x: 1.209, y: 0.403 },
					},
					width: 0.403,
					depth: 0.403,
					height: 0.06,
					protrude: 0,
				},
			},
		},
	},
});