import { ___ } from 'classes/Translation';

import GatesAluminiumVertical from './GatesAluminiumVertical';
import GatesAluminiumHorizontal from './GatesAluminiumHorizontal';
import GatesAluminiumSecret from './GatesAluminiumSecret';


export default () => ({
	label: ___('Aluminiowe'),
	systems: {
		vertical: GatesAluminiumVertical(),
		horizontal: GatesAluminiumHorizontal(),
		secret: GatesAluminiumSecret(),
	},
});