import { ___ } from 'classes/Translation';

import WicketsAluminiumVertical from './WicketsAluminiumVertical';
import WicketsAluminiumHorizontal from './WicketsAluminiumHorizontal';
import WicketsAluminiumSecret from './WicketsAluminiumSecret';


export default () => ({
	label: ___('Aluminiowe'),
	systems: {
		vertical: WicketsAluminiumVertical(),
		horizontal: WicketsAluminiumHorizontal(),
		secret: WicketsAluminiumSecret(),
	},
});