import { ___ } from 'classes/Translation';

import FencingsAluminiumVertical from './FencingsAluminiumVertical';
import FencingsAluminiumHorizontal from './FencingsAluminiumHorizontal';
import FencingsAluminiumSecret from './FencingsAluminiumSecret';


export default () => ({
	label: ___('Aluminiowe'),
	systems: {
		vertical: FencingsAluminiumVertical(),
		horizontal: FencingsAluminiumHorizontal(),
		secret: FencingsAluminiumSecret(),
	},
});